<template>
  <section class="last-changes block">
    <div class="last-changes-header block-wrapper header">
      <h2>Last comments</h2>
      <!--      <button class="btn">Hide new</button>-->
    </div>
    <div class="table-container">
      <Preloader v-if="pending"/>
      <table v-if="list.length > 0" class="last-changes-table">
        <thead class="ready-articles-table-header">
        <tr>
          <td></td>
          <td>User</td>
          <td>Article</td>
          <td>Comment</td>
          <td>Date add</td>
        </tr>
        </thead>
        <tbody>

        <tr v-for="(comment, idx) in list" :key="`change-${idx}`">
          <td>#{{ idx + 1 + ((page - 1) * quantity) }}</td>
          <td><span class="last-changes-name">{{ comment.author_email }}</span></td>
          <td class="col col-title ready-articles-item-title">
            <div class="df">
              <span class="lang">{{ comment.text_language }}</span>
              <router-link :to="`/dashboard/articles/${comment.text_id}`">
                <span>{{ comment.text_title }}</span>
              </router-link>
            </div>
          </td>
            <!--            <div class="last-changes-country">-->
            <!--              <span>[{{ item.country }}]</span>-->
            <!--              <component :is="item.country"></component>-->
            <!--            </div>-->
          <td>
            {{ comment.comment }}
            <!--            <p>{{ item.net.ip }}</p>-->
            <!--            <p>pol:{{ item.net.pol }}</p>-->
          </td>
          <td>
            <p>{{ $moment(comment.created_at).format('YYYY-MM-DD') }}</p>
          </td>
          <!--          <td><span class="last-changes-next">Next text</span></td>-->
          <!--          <td><span class="last-changes-ready c-green">{{ (item.ready) }}%</span></td>-->
          <!--          <td>-->
          <!--            <router-link :to="`/dashboard/articles/${item.id}`" class="btn transparent">Open</router-link>-->
          <!--          </td>-->
        </tr>
        </tbody>
      </table>
      <div v-else class="table-empty">
        <p v-if="!pending">Empty</p>
      </div>
      <APagination ref="pagination" :visible="list.length > 0" :page="page" :count="paginationPages"
                   :callback="setPagination"/>
    </div>
  </section>
</template>

<script>
import Preloader from '@/components/common/Preloader';
import {mapActions} from 'vuex';
import {eventBus} from '@/main';

export default {
  name: "LastComments",
  components: {
    Preloader,
    APagination: () => import('@/components/common/APagination'),
  },
  data() {
    return {
      pending: 0,
      list: [],
      page: 1,
      quantity: 30,
      total: 0,
    }
  },
  computed: {
    paginationPages() {
      let pageNum = Math.trunc(this.total / this.quantity);
      if ((this.total % this.quantity) > 0) {
        pageNum++;
      }
      return pageNum;
    },
  },
  methods: {
    ...mapActions(['getLastComments']),
    async setPagination(num) {
      this.page = num;
      await this.loadComments();
    },
    async loadComments() {
      this.pending = true;

      await this.getLastComments({per_page: this.quantity, current_page: this.page}).then(({data, pagination}) => {
        this.pending = false;
        this.total = pagination.total;
        this.list = data;

        if (data.length === 0 && this.page > 1) {
          this.$refs['pagination'].onChangePage(1);
        }
      }).catch(error => {
        this.pending = false;
        this.$notify({
          title: 'Last comments list',
          text: error.response.data.message,
          type: 'error'
        });
      });
    },
  },
  async created() {
    eventBus.$on('onLoadComments', async data => {
      await this.loadComments();
    });
  }
}
</script>

<style scoped>

</style>
